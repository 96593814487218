import { BASE } from "../index";
import axios from "../http";

const BASE_URL = BASE.PRO1;

/**
 * 总控-自提点列表
 * @param {*} params
 * @returns
 */
export function postDeliveryListNew(params = {}) {
  return axios.post(`${BASE_URL}/delivery/list/new`, params);
}
