<template>
  <div class="entryWrap">
    <!-- 表单搜索 start -->
    <Filtersearch
      ref="Filtersearchref"
      :list="Filtersearchlist"
      :fromdata="formData"
      @Refresh="onHandleRefresh"
      @search="onSearch"
    >
      <template v-slot:right>
        <el-button
          type="warning"
          :disabled="!tableData.length || disabledExport"
          @click="exportHandle"
          icon="el-icon-download"
          >导出</el-button
        >
      </template>
    </Filtersearch>
    <!-- 表单搜索 end -->
    <!-- 列表 start -->
    <div class="table-wrap">
      <el-table
        :data="tableData"
        height="100%"
        v-loading="loading"
        :border="true"
        :header-cell-style="{ color: '#333333', background: '#EFF6FF' }"
      >
        <el-table-column type="index" width="100px" align="center" label="ID">
        </el-table-column>

        <el-table-column
          prop="logistics"
          align="center"
          label="集配中心"
          min-width="150"
        >
          <template slot-scope="scope">
            {{ `${scope.row.logistics.business.name}` }}
          </template>
        </el-table-column>
        <el-table-column
          prop="logistics"
          align="center"
          label="城市仓名称"
          min-width="200"
        >
          <template slot-scope="scope">
            {{ `${scope.row.logistics.name}` }}
          </template>
        </el-table-column>
        <el-table-column
          prop="name"
          align="center"
          label="自提点名称"
          min-width="150"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            {{ scope.row.name || "-" }}
            <el-tooltip
              class="item"
              effect="dark"
              :content="scope.row.location_address"
              v-if="scope.row.location_address"
              placement="top"
            >
              <i class="el-icon-location-outline"></i>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column
          prop="address"
          align="center"
          label="自提点地址"
          min-width="150"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            {{ scope.row.address || "-" }}
          </template>
        </el-table-column>
        <el-table-column prop="mobile" align="center" label="联系电话">
          <template slot-scope="scope">
            {{ scope.row.mobile || "-" }}
          </template>
        </el-table-column>
        <el-table-column
          prop="freight_parameter"
          align="center"
          label="运费标准(元)"
          min-width="150"
        >
          <template slot-scope="scope">
            {{ scope.row.freight_parameter }}
          </template>
        </el-table-column>
        <el-table-column
          prop="img_url"
          align="center"
          label="状态"
          min-width="150"
        >
          <template slot-scope="scope">
            {{
              Object.values(DISABLED_ENUM).find(
                (child) => child.value === scope.row.status
              ).label
            }}
          </template>
        </el-table-column>
        <el-table-column
          prop="create_time"
          align="center"
          label="创建时间"
          min-width="150"
        >
        </el-table-column>
        <el-table-column
          prop="op_name"
          align="center"
          label="操作"
          fixed="right"
          min-width="150"
        >
          <template slot-scope="scope">
            <el-button
              type="text"
              v-if="scope.row.status !== DISABLED_ENUM.closed.value"
              @click="onHandleEditData(scope.row, scope.row.logistics)"
              >编辑</el-button
            >
            <el-button
              type="text"
              @click="onHandleDisabled(scope.row)"
              v-if="scope.row.status !== DISABLED_ENUM.closed.value"
              >{{
                scope.row.status === DISABLED_ENUM.unDisabled.value
                  ? "禁用"
                  : "启用"
              }}
            </el-button>
            <el-button type="text" @click="onHandleEditLog(scope.row)"
              >变更记录</el-button
            >
            <el-button
              type="text"
              v-if="scope.row.status !== DISABLED_ENUM.closed.value"
              @click="onHandleDeliveryClose(scope.row)"
              >永久关闭</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 列表 end -->

    <!-- 分页 start -->
    <div class="fy">
      <el-pagination
        type="primary"
        background
        @size-change="onHandleSizeChange"
        @current-change="onHandleCurrentChange"
        :current-page="formData.page"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="formData.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>

    <!-- 新增/编辑自提点 start -->
    <edit-delivery-dialog
      ref="refEditDelivery"
      :bankList="bankList"
      @on-add-delivery="onHandleAddDelivery"
      @on-edit-delivery="onHandleDeliverDetail"
    ></edit-delivery-dialog>
    <!-- 新增/编辑自提点 end -->

    <!-- 自提点变更记录 start -->
    <delivery-edit-log-dialog
      ref="refDeliveryEditLog"
    ></delivery-edit-log-dialog>
    <!-- 自提点变更记录 end -->
  </div>
</template>
<script>
import { postDeliveryListExport } from "@/api/export/center.js";
import { DISABLED_LIST, LOCATION_LIST, DISABLED_ENUM } from "./utils/enum";
import { postDeliveryListNew } from "@/api/generalControl/self-point-management.js";
import EditDeliveryDialog from "../../general/city-store-manage/modules/edit-delivery-dialog/index";
import DeliveryEditLogDialog from "../../general/city-store-manage/modules/delivery-edit-log-dialog/index";
import { postCityStoreDeliveryClose } from "@/api/general/city-store";
import Filtersearch from "@/components/Filtersearch.vue";

export default {
  name: "self-point-management",
  components: {
    EditDeliveryDialog,
    DeliveryEditLogDialog,
    Filtersearch,
  },
  data() {
    return {
      Filtersearchlist: [
        {
          type: "select",
          name: "自提点定位",
          clearable: true,
          model: "is_location",
          placeholder: "请选择自提点定位",
          label: "label",
          value: "value",
          selectoption: LOCATION_LIST,
        },

        {
          type: "select",
          name: "集配中心",
          clearable: true,
          model: "logistic_business_id",
          placeholder: "请选择集配中心",
          selectoptionname: "logisticsList",
          label: "name",
          value: "id",
          selectoption: [],
        },
        {
          type: "input",
          name: "城市仓名称",
          clearable: true,
          model: "tdc_name",
          placeholder: "请输入城市仓名称",
        },
        {
          type: "select",
          name: "状态",
          clearable: true,
          model: "status",
          placeholder: "请选择状态",
          label: "label",
          value: "value",
          selectoption: DISABLED_LIST,
        },
        {
          type: "input",
          name: "自提点名称",
          clearable: true,
          model: "delivery_name",
          placeholder: "请输入自提点名称",
        },
      ],
      DISABLED_LIST,
      LOCATION_LIST,
      DISABLED_ENUM,
      bankList: [], // 银行列表
      options: [],
      typeList: [],
      loading: false,
      disabledExport: false, // 导出禁用
      formData: {
        page: 1, //	number	页码
        pageSize: 100, //	number	页大小
        logistic_business_id: "",
        tdc_name: "",
        delivery_name: "",
        status: "",
        is_location: "",
      }, // 表单参数
      tableData: [], // 城市仓列表
      total: 0,
      detail: {},
      remark: "",
      type: "",
      industry: [], // 从业经验
      investment: [], // 预算
      education: [], // 文化程度
      advantage: [], // 优势
      province_list: [], // 省
      city_list: [], // 市
      district_list: [], // 区
    };
  },
  computed: {},
  mounted() {},
  created() {
    if (this.$route.query && this.$route.query.name) {
      this.formData.tdc_name = this.$route.query.name;
    }
    this.onInitData();
  },
  methods: {
    /**
     * 查看自提点
     */
    onHandleDeliverDetail(row) {
      this.hqlist();
      // console.log("🆒 onHandleDeliverDetail", row);
      // if (!row.delivery_number) {
      //   this.$message.warning("该城市仓下暂无自提点~");
      //   return;
      // }
      // this.$refs.refDeliveryList.onInitData(row);
    },
    /**
     * 新增自提点，成功后更新数量，减少一次接口
     */
    onHandleAddDelivery(data) {
      // const index = this.tableData.findIndex((item) => item.id === data);
      // this.tableData[index].delivery_number++;
    },
    /**
     * 编辑自提点
     */
    onHandleEditData(data, row) {
      const params = {
        module: "edit",
        ...data,
      };
      this.$refs.refEditDelivery.onInitData(params, row);
    },
    // 获取集配中心
    getBusinessList() {
      this.$api.general.businessList().then((res) => {
        this.options = res.data;
        this.Filtersearchlist.forEach((el) => {
          if (
            el.type == "select" &&
            el.selectoptionname &&
            el.selectoptionname == "logisticsList"
          ) {
            el.selectoption = [{ area_code: "", name: "全部" }, ...res.data];
          }
        });
      });
    },
    /**
     * 获取银行列表
     */
    async ajaxGetBankList() {
      try {
        const { data } = await this.$api.general.banksList();
        this.bankList = data;
      } catch (err) {
        console.log("ajax banksList err", err);
      }
    },
    /**
     * 自提点启用/禁用，二次确认
     */
    onHandleDisabled(row) {
      const msg =
        row.status === DISABLED_ENUM.unDisabled.value
          ? "确定要禁用吗？"
          : "确定要启用吗？";
      this.$confirm(msg, "提示", {
        center: true,
        confirmButtonText: "确 定",
        cancelButtonText: "取 消",
        customClass: "jn-confirm-message-style",
        iconClass: "el-icon-warning",
      }).then(() => {
        this.ajaxPostDeliveryStatus(row);
      });
    },
    /**
     * 自提点启用/禁用，接口请求
     */
    async ajaxPostDeliveryStatus(row) {
      const status =
        row.status === DISABLED_ENUM.unDisabled.value
          ? DISABLED_ENUM.disabled.value
          : DISABLED_ENUM.unDisabled.value;
      const params = {
        delivery_id: row.id,
        status,
      };
      try {
        await this.$api.general.deliveryStatus(params);
        // 成功后修改值，减少一次接口请求
        // const rowIndex = this.tableData.findIndex((item) => item.id === row.id);
        // this.tableData[rowIndex].status = status;
        const msg =
          row.status === DISABLED_ENUM.unDisabled.value
            ? "禁用成功"
            : "启用成功";
        this.$message.success(msg);
        this.hqlist();
      } catch (err) {
        console.log("ajax ajaxPostDeliveryStatus err", err);
      }
    },
    /**
     * 变更记录
     */
    onHandleEditLog(data, row) {
      //this.$emit("on-edit-log", row, this.tableRow);
      const params = {
        ...data,
      };
      this.$refs.refDeliveryEditLog.onInitData(params, row);
    },
    /**
     * 自提点永久关闭，二次确认
     */
    onHandleDeliveryClose(row) {
      this.$confirm("确定要永久关闭该自提点吗？", "提示", {
        center: true,
        confirmButtonText: "确 定",
        cancelButtonText: "取 消",
        customClass: "jn-confirm-message-style",
        iconClass: "el-icon-warning",
      }).then(() => {
        this.ajaxPostCityStoreDeliveryClose(row);
      });
    },
    /**
     * 自提点永久关闭
     */
    async ajaxPostCityStoreDeliveryClose(row) {
      const params = {
        delivery_id: row.id,
      };
      try {
        await postCityStoreDeliveryClose(params);
        this.$message.success("操作成功");
        this.onChangeStatus();
      } catch (err) {
        console.log("ajax postCityStoreDeliveryClose err", err);
      }
    },
    // 初始化
    onInitData() {
      this.getBusinessList();
      this.hqlist();
      this.ajaxGetBankList();
    },
    // 获取列表
    async hqlist() {
      this.loading = true;
      try {
        const res = await postDeliveryListNew(this.formData);
        this.loading = false;
        this.tableData = res.data.data;
        this.total = res.data.total;
      } catch (error) {
        console.log(error, "postDeliveryListNew");
      }
    },
    /**
     * 导出
     */
    async exportHandle() {
      let params = {
        ...this.formData,
      };
      delete params.page;
      delete params.pageSize;
      this.disabledExport = true;
      try {
        const res = await postDeliveryListExport(params);
        this.goExportCenter();
      } catch (error) {
        console.log(error, "postDeliveryListExport");
      }
    },
    /**
     * 导出后引导弹框
     */
    goExportCenter() {
      // 新窗口打开下载中心
      const routerInfo = this.$router.resolve({
        path: "/export-center",
      });
      window.open(routerInfo.href, "_blank");
      this.disabledExport = false;
    },
    /**
     * 重置表单，并刷新
     */
    onHandleRefresh() {
      Object.assign(this.$data.formData, this.$options.data().formData);
      this.hqlist();
    },
    /**
     * 搜索表单
     */
    onSearch(e) {
      this.formData.page = 1;
      this.formData = e;
      this.hqlist();
    },
    /**
     * 每页条数发生改变
     */
    onHandleSizeChange(val) {
      this.formData.pageSize = val;
      this.hqlist();
    },
    /**
     * 页码发生改变
     */
    onHandleCurrentChange(val) {
      this.formData.page = val;
      this.hqlist();
    },
  },
};
</script>
<style lang="scss" scoped>
.entryWrap {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  height: 100%;
  box-sizing: border-box;
  padding: 10px;

  .form-wrap {
    background: #ffffff;
    padding: 13px 15px;
    box-sizing: border-box;
    display: flex;
    .seach {
      flex: 1;
      display: flex;
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      .inputs {
        display: flex;
        align-items: center;
      }
    }
    .refresh {
      background: #333333;
      border-color: #333333;
    }
  }
  .table-wrap {
    flex: 1;
    overflow-y: hidden;
    .text-btn {
      color: $theme-color;
      cursor: pointer;
    }
    .el-button {
      &--text {
        padding: 10px 0;
      }
    }
  }
  .fy {
    box-sizing: border-box;
    padding: 10px 20px;
  }
  .contents {
    padding-left: 55px;
    padding-right: 55px;
    padding-top: 23px;
  }
}
</style>
