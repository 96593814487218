var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "entryWrap" },
    [
      _c("Filtersearch", {
        ref: "Filtersearchref",
        attrs: { list: _vm.Filtersearchlist, fromdata: _vm.formData },
        on: { Refresh: _vm.onHandleRefresh, search: _vm.onSearch },
        scopedSlots: _vm._u([
          {
            key: "right",
            fn: function () {
              return [
                _c(
                  "el-button",
                  {
                    attrs: {
                      type: "warning",
                      disabled: !_vm.tableData.length || _vm.disabledExport,
                      icon: "el-icon-download",
                    },
                    on: { click: _vm.exportHandle },
                  },
                  [_vm._v("导出")]
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c(
        "div",
        { staticClass: "table-wrap" },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              attrs: {
                data: _vm.tableData,
                height: "100%",
                border: true,
                "header-cell-style": {
                  color: "#333333",
                  background: "#EFF6FF",
                },
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  type: "index",
                  width: "100px",
                  align: "center",
                  label: "ID",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "logistics",
                  align: "center",
                  label: "集配中心",
                  "min-width": "150",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(`${scope.row.logistics.business.name}`) +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "logistics",
                  align: "center",
                  label: "城市仓名称",
                  "min-width": "200",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " + _vm._s(`${scope.row.logistics.name}`) + " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "name",
                  align: "center",
                  label: "自提点名称",
                  "min-width": "150",
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(" " + _vm._s(scope.row.name || "-") + " "),
                        scope.row.location_address
                          ? _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: {
                                  effect: "dark",
                                  content: scope.row.location_address,
                                  placement: "top",
                                },
                              },
                              [
                                _c("i", {
                                  staticClass: "el-icon-location-outline",
                                }),
                              ]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "address",
                  align: "center",
                  label: "自提点地址",
                  "min-width": "150",
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(" " + _vm._s(scope.row.address || "-") + " "),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "mobile", align: "center", label: "联系电话" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(" " + _vm._s(scope.row.mobile || "-") + " "),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "freight_parameter",
                  align: "center",
                  label: "运费标准(元)",
                  "min-width": "150",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(" " + _vm._s(scope.row.freight_parameter) + " "),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "img_url",
                  align: "center",
                  label: "状态",
                  "min-width": "150",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              Object.values(_vm.DISABLED_ENUM).find(
                                (child) => child.value === scope.row.status
                              ).label
                            ) +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "create_time",
                  align: "center",
                  label: "创建时间",
                  "min-width": "150",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "op_name",
                  align: "center",
                  label: "操作",
                  fixed: "right",
                  "min-width": "150",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.status !== _vm.DISABLED_ENUM.closed.value
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.onHandleEditData(
                                      scope.row,
                                      scope.row.logistics
                                    )
                                  },
                                },
                              },
                              [_vm._v("编辑")]
                            )
                          : _vm._e(),
                        scope.row.status !== _vm.DISABLED_ENUM.closed.value
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.onHandleDisabled(scope.row)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    scope.row.status ===
                                      _vm.DISABLED_ENUM.unDisabled.value
                                      ? "禁用"
                                      : "启用"
                                  ) + " "
                                ),
                              ]
                            )
                          : _vm._e(),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.onHandleEditLog(scope.row)
                              },
                            },
                          },
                          [_vm._v("变更记录")]
                        ),
                        scope.row.status !== _vm.DISABLED_ENUM.closed.value
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.onHandleDeliveryClose(scope.row)
                                  },
                                },
                              },
                              [_vm._v("永久关闭")]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "fy" },
        [
          _c("el-pagination", {
            attrs: {
              type: "primary",
              background: "",
              "current-page": _vm.formData.page,
              "page-sizes": [10, 20, 50, 100],
              "page-size": _vm.formData.pageSize,
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.total,
            },
            on: {
              "size-change": _vm.onHandleSizeChange,
              "current-change": _vm.onHandleCurrentChange,
            },
          }),
        ],
        1
      ),
      _c("edit-delivery-dialog", {
        ref: "refEditDelivery",
        attrs: { bankList: _vm.bankList },
        on: {
          "on-add-delivery": _vm.onHandleAddDelivery,
          "on-edit-delivery": _vm.onHandleDeliverDetail,
        },
      }),
      _c("delivery-edit-log-dialog", { ref: "refDeliveryEditLog" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }