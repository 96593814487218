/** 状态数组 */
export const DISABLED_LIST = [
  {
    value: "",
    label: "全部状态",
  },
  {
    value: 0,
    label: "禁用",
  },
  {
    value: 1,
    label: "启用",
  },
  {
    value: 2,
    label: "已关仓",
  },
];

// 定位状态
export const LOCATION_LIST = [
  {
    value: "",
    label: "全部",
  },
  {
    value: 1,
    label: "已定位",
  },
  {
    value: 0,
    label: "未定位",
  },
];

/** 禁用枚举 */
export const DISABLED_ENUM = {
  disabled: {
    value: 0,
    label: "已禁用",
  },
  unDisabled: {
    value: 1,
    label: "已启用",
  },
  closed: {
    value: 2,
    label: "已关仓",
  },
};

/** 升仓标识枚举 */
export const UPGRADE_POSITION_ENUM = {
  common: {
    value: 1,
    label: "未升仓",
  },
  upgrade: {
    value: 2,
    label: "已升仓",
  },
};
